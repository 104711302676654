.energyField {

}
.energyFieldItem {
    padding: 3px 5px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    background: red;
    display: inline-flex;
    &:first-child{
        border-radius: 4px 0 0 4px;
    }
    &:last-child{
        border-radius: 0px 4px 4px 0px;
    }
}

.activeDay {
    background: #006edc;
    color: white;
}