$bgcolor: red;
.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid rgba($color: #000000, $alpha: .1);
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  
    &--doubleView {
      width: 700px;
  
      .react-calendar__viewContainer {
        display: flex;
        margin: -.5em;
  
        > * {
          width: 50%;
          margin: .5em;
        }
      }
    }
  
    &, & *, & *:before, & *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  
    button {
      margin: 0;
      border: 0;
      outline: none;
  
      &:enabled {
        &:hover {
          cursor: pointer;
        }
      }
    }
  
    &__navigation {
      height: 44px;
      margin-bottom: 1em;
  
      button {
        min-width: 44px;
        background: none;
  
        &:enabled {
          &:hover, &:focus {
            background-color: rgb(230, 230, 230);
          }
        }
  
        &[disabled] {
          background-color: rgb(240, 240, 240);
        }
      }
    }
  
    &__month-view {
      &__weekdays {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: .75em;
  
        &__weekday {
          padding: .5em;
        }
      }
  
      &__weekNumbers {
        font-weight: bold;
  
        .react-calendar__tile {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: .75em;
          padding: calc(.75em / .75) calc(.5em / .75);
        }
      }
  
      &__days {
        &__day {
          &--weekend {
            color: rgb(209, 0, 0);
          }
  
          &--neighboringMonth {
            color: rgb(117, 117, 117)
          }
        }
      }
    }
  
    &__year-view,
    &__decade-view,
    &__century-view {
      .react-calendar__tile {
        padding: 2em .5em;
      }
    }
  
    &__tile {
      max-width: 100%;
      text-align: center;
      padding: .75em .5em;
      background: none;
  
      &:disabled {
        background-color: rgb(240, 240, 240);
      }
  
    //   &:enabled {
    //     &:hover, &:focus {
    //       background-color: rgb(230, 230, 230);
    //     }
    //   }
  
      &--now {
        background: rgba($color: #000000, $alpha: .1);
  
        &:enabled {
          &:hover, &:focus {
          }
        }
      }
  
    //   &--hasActive {
    //     background: $bgcolor;
  
    //     &:enabled {
    //       &:hover, &:focus {
    //         background: lighten($bgcolor, 10%);
    //       }
    //     }
    //   }
  
    //   &--active {
    //     background: $bgcolor;
    //     color: white;
  
    //     &:enabled {
    //       &:hover, &:focus {
    //         background: lighten($bgcolor, 10%);
    //       }
    //     }
    //   }
    }
  
    &--selectRange {
      .react-calendar__tile {
        &--hover {
          background-color: rgb(230, 230, 230);
        }
      }
    }
  }