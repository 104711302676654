@import "../../styles/vars";
.rug {
  font-family: Helvetica;
  font-size: 16px;
  color: #3d4852;

  img {
    pointer-events: none;
    user-select: none;
  }

  .rug-file-input {
    display: none !important;
  }

  .rug-item {
    justify-content: center;
    user-select: none;
    cursor: grab;
  }

  .rug-items.__card {
    display: -ms-grid;
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    margin: 0 -10px;
    min-height: 100px;

    @media (max-width: 450px) {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }

    .rug-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .rug-items.__list {
    padding-top: 30px;
    min-height: 100px;
  }

  .rug-handle {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      border-radius: 4px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
    }

    svg.rug-handle-icon {
      width: 20px;

      polyline,
      line {
        fill: none;
        stroke: #3d4852;
        stroke-linejoin: round;
        stroke-width: 1px;
        stroke-linecap: round;
      }
    }

    &.__dragging {
      &:before {
        border: 2px dashed rgba($color: #000000, $alpha: 0.5);
      }
    }

    .rug-handle-info {
      position: relative;

      .rug-handle-drop-text {
        font-size: 16px;
      }

      .rug-handle-button {
        background-color: $primary;
        border-radius: 3px;
        padding: 4px 10px;
        font-size: 13px;
        color: #f5f5f5;
        text-align: center;
        cursor: pointer;
        max-width: 250px;
        display: block;
        margin: 0 auto;

        &:hover {
          background-color: darken($color: $primary, $amount: 10);
        }
      }

      span {
        text-align: center;
        padding: 10px 0;
        font-size: 13px;
        display: block;
      }
    }
  }
}

@media (max-width: 960px) {
  .rug .rug-handle {
    height: 100px;
    svg {
      width: 50px;
    }
    .rug-handle-drop-text,
    span {
      display: none !important;
    }
  }
}

.rug-card {
  width: 100px;
  height: 100px;
  background-color: #3d4852;
  border-radius: 5px;
  position: relative;
  font-family: Helvetica;
  overflow: hidden;
  cursor: pointer;
  margin: 5px;

  &:hover {
    .rug-card-name {
      opacity: 1;
    }
  }

  @media (max-width: 450px) {
    width: 160px;
    height: 160px;
  }

  &.__error {
    border: 3px solid #ff2d55;
  }

  .rug-card-image {
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .rug-card-name {
    opacity: 0;
    position: absolute;
    z-index: 15;
    height: 80px;
    width: 100%;
    font-weight: 700;
    font-size: 12px;
    white-space: nowrap;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0.7) 29%,
      transparent
    );
    background-blend-mode: multiply;
    color: #f5f5f5;

    > div {
      margin: 10px;
      overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .rug-card-size {
    color: #c3c3c3;
    font-size: 11px;
  }

  .rug-card-progress {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    display: block;

    .__progress-cricle {
      stroke: $primary;
      fill: none;
      stroke-width: 5;
      stroke-linecap: round;
      transition: stroke-dasharray 200ms ease;
    }
  }

  .rug-card-progress-count {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }

  .rug-card-refresh {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 20;
    border: 2px solid transparent;

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
      border: 2px solid #3d4852;
    }

    .__refresh-icon g {
      fill: #3d4852;
    }

    &.__spin {
      animation: __spin 1000ms linear infinite;
      border: 2px solid $primary !important;

      .__refresh-icon g {
        fill: $primary;
      }
    }
  }

  .rug-card-upload-button {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 20;
    text-align: center;
    border: 2px solid transparent;

    svg {
      width: 25px;
      margin-top: 6px;

      polyline,
      line {
        fill: none;
        stroke: #3d4852;
        stroke-linejoin: round;
        stroke-width: 3px;
        stroke-linecap: round;
      }
    }

    &:hover {
      border: 2px solid $primary;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  .rug-card-remove {
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    background-color: #3d4852;
    opacity: 0.75;
    border-radius: 9999px;
    margin: 4px;
    padding: 4px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid transparent;
    transition: border 200ms ease;
    cursor: pointer;

    &:hover {
      border: 1.5px solid #fff;
    }

    svg {
      stroke: currentColor;
      height: 18px;
      width: 18px;
    }
  }
}

@keyframes __spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rug-list {
  width: 100%;
  background-color: #3d4852;
  border-radius: 3px;
  position: relative;
  font-family: Helvetica;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  &.__error {
    border: 3px solid #ff2d55;
  }

  .rug-list-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 5px;
    background: $primary;
    border: 3px;
    opacity: 0;
    transition: all 200ms ease;

    &.__active {
      opacity: 1;
    }
  }
  .rug-list-progress-count {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: #f5f5f5;
    font-size: 13px;
    opacity: 0;
    transition: all 200ms ease;

    &.__active {
      opacity: 1;
    }
  }

  .rug-list-refresh {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 20;
    border: 2px solid transparent;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }

    .__refresh-icon g {
      fill: #3d4852;
    }

    &.__spin {
      animation: __spin 1000ms linear infinite;

      .__refresh-icon g {
        fill: $primary;
      }
    }
  }

  .rug-list-upload-button {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 20;
    text-align: center;
    border: 2px solid transparent;
    cursor: pointer;

    svg {
      width: 15px;
      margin-top: 2px;

      polyline,
      line {
        fill: none;
        stroke: #3d4852;
        stroke-linejoin: round;
        stroke-width: 3px;
        stroke-linecap: round;
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  .rug-list-image {
    width: 100px;
    height: 70px;
    padding: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .rug-list-content {
    padding: 5px;
    width: calc(100% - 120px);

    .rug-list-name {
      color: #f5f5f5;
      white-space: nowrap;
      font-size: 14px;
      text-overflow: ellipsis;
      position: relative;
      overflow: hidden;
      width: calc(100% - 40px);
    }

    .rug-list-size {
      color: #ddd;
      font-size: 12px;
      margin-top: 3px;
    }
  }
  .rug-list-remove {
    position: absolute;
    z-index: 40;
    top: 0;
    right: 0;
    color: #ccc;
    opacity: 0.75;
    border-radius: 9999px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      stroke: currentColor;
      height: 22px;
      width: 22px;
    }

    &:hover {
      color: #fff;
    }
  }
}

@keyframes __spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.__sorting {
  .rug-list,
  .rug-card {
    cursor: grab;
  }
}

.rug-dragging-item {
  cursor: grabbing;
  & > div:not(.rug-list) {
    transform: scale(1.1);
  }
}
