@import "./login.scss";

@font-face {
	font-family: "comfort";
	src: url("../fonts/comfort.eot") format("eot"),
		url("../fonts/comfort.woff") format("woff"),
		url("../fonts/comfort.ttf") format("truetype"),
		url("../fonts/comfort.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.ant-tabs-tabpane {
	outline: none;
}
.text-editor {
	font-family: inherit !important;
}

.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	background: none;
	&.page {
		margin-top: 50px;
		margin-bottom: 50px;
	}
}

@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
}

.align-left {
	text-align: left;
}
.align-right {
	text-align: right;
}
.align-center {
	text-align: center;
}

.site-layout .site-layout-background {
	background: #fff;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
	color: #999;
	font-size: 32px;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}

#searchForm {
	margin-top: 30px;
	margin-bottom: 30px;
	.ant-form-item {
		margin: 0px 30px 30px 0;
	}
	.ant-select {
		min-width: 200px;
	}
}
